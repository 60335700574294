/* Set content for font-families */
.ql-container {
  font-family: 'Roboto', 'sans-serif';
  font-size: 14px;
}

.ql-font-arial,
.ql-font span[data-value='arial']::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value='comic-sans']::before {
  font-family: 'Comic Sans MS', cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value='courier-new']::before {
  font-family: 'Courier New';
}
.ql-font-georgia,
.ql-font span[data-value='georgia']::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value='helvetica']::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value='lucida']::before {
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value='extra-small']::before {
  font-size: 11px !important;
}
.ql-size-small,
.ql-size span[data-value='small']::before {
  font-size: 12px !important;
}
.ql-size-large,
.ql-size span[data-value='large']::before {
  font-size: 18px !important;
}
.ql-size-huge,
.ql-size span[data-value='huge']::before {
  font-size: 24px !important;
}

/* Image tooltip text */
.ql-tooltip[data-mode='image']::before {
  content: 'Enter image URL:';
}

/* Video styles */
.ql-custom-video {
  max-width: 100%;
  height: auto;
}

/* Image styles */
.ql-custom-image {
  max-width: 100%;
  height: auto;
}
